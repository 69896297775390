import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { useContext } from "react";
import { GridToolbar, LayoutContext, TableContainer } from "wcz-layout";
import { TrailValueList } from "../components/trail/TrailValueList";
import Trail from "../models/Trail";
import { useGetTrails } from "../queries/TrailQueries";

export const LogsPage: React.FC = () => {
    const { t } = useContext(LayoutContext);

    const { data, isFetching } = useGetTrails();

    const columns: GridColDef<Trail>[] = [
        { field: "dateTime", headerName: t("Date"), type: "dateTime", width: 200, valueGetter: value => value && new Date(value) },
        { field: "userName", headerName: t("UpdatedBy"), width: 150, },
        { field: "userId", headerName: "ID", width: 150, },
        { field: "type", headerName: t("Type"), width: 150, },
        { field: "tableName", headerName: t("Name"), width: 150, },
        {
            field: "oldValues", headerName: t("OldValues"), width: 500, renderCell: ({ value }) => {
                if (!value) return null;
                const object = JSON.parse(value);
                return <TrailValueList object={object} />;
            }
        },
        {
            field: "newValues", headerName: t("NewValues"), width: 500, renderCell: ({ value }) => {
                if (!value) return null;
                const object = JSON.parse(value);
                return <TrailValueList object={object} />;
            }
        },
    ];

    return (
        <TableContainer>
            <DataGridPremium
                rows={data}
                columns={columns}
                slots={{ toolbar: GridToolbar }}
                loading={isFetching}
                ignoreDiacritics
            />
        </TableContainer>
    );
};