import CartItem from "./CartItem";
import Employee from "./Employee";
import { TransferType } from "./enums/TransferType";

export default interface Cart {
    id: string,
    employee: Employee | null,
    type: TransferType,
    items: CartItem[]
};

export const initCart: Cart = {
    id: "",
    employee: null,
    type: TransferType.Delivery,
    items: [],
}