import { GridRowId } from "@mui/x-data-grid-premium";
import { DefinedInitialDataOptions, UseMutationOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import { Error, LayoutContext, fetchGet, fetchPost } from "wcz-layout";
import Handover, { initHandover } from "../models/Handover";
import { baseUrl } from "../utils/BaseUrl";

const route: string = "v1/Handover";

export const useGetHandovers = (options?: Omit<DefinedInitialDataOptions<Handover[], Error, Handover[]>, "queryKey" | "queryFn" | "initialData">) => {
    const { snackbar } = useContext(LayoutContext);

    const query = useQuery<Handover[], Error, Handover[]>({
        ...options,
        queryKey: [route],
        queryFn: ({ signal }) => fetchGet(`${baseUrl}/${route}`, signal),
        initialData: [],
    });

    useEffect(() => {
        if (query.error)
            snackbar({ title: query.error.message, severity: "error", description: query.error.innerException });
    }, [query.isError]);

    return query;
};

export const useGetHandover = (id: GridRowId, options?: Omit<DefinedInitialDataOptions<Handover, Error, Handover>, "queryKey" | "queryFn" | "initialData">) => {
    const { snackbar } = useContext(LayoutContext);

    const query = useQuery<Handover, Error, Handover>({
        ...options,
        queryKey: [route, id],
        queryFn: ({ signal }) => fetchGet(`${baseUrl}/${route}/${id}`, signal),
        initialData: initHandover,
    });

    useEffect(() => {
        if (query.error)
            snackbar({ title: query.error.message, severity: "error", description: query.error.innerException });
    }, [query.isError]);

    return query;
};

export const useCreateHandover = (options?: Omit<UseMutationOptions<Handover, Error, Handover>, "mutationFn" | "onMutate" | "onError" | "onSettled">) => {
    const { snackbar } = useContext(LayoutContext);
    const queryClient = useQueryClient();

    return useMutation<Handover, Error, Handover>({
        ...options,
        mutationFn: model => fetchPost(`${baseUrl}/${route}`, model),
        onMutate: async model => {
            await queryClient.cancelQueries({ queryKey: [route], exact: false });

            const previousDataList = queryClient.getQueryData([route]);
            if (previousDataList)
                queryClient.setQueryData([route], (old: Handover[]) => [model, ...old]);

            return { previousDataList };
        },
        onError: (error, _newTodo, context: any) => {
            queryClient.setQueryData([route], context.previousDataList);

            snackbar({ title: error.message, severity: "error", description: error.innerException });
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [route], exact: false });
            queryClient.invalidateQueries({ queryKey: ["v1/Material"], exact: false });
        }
    });
};