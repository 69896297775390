import { Button, Card, CardActions, CardContent, CardHeader, Container, Grid2, TextField } from "@mui/material";
import { useForm } from "@tanstack/react-form";
import { ZodValidator, zodValidator } from "@tanstack/zod-form-adapter";
import { FormEvent, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LayoutContext, newGuid } from "wcz-layout";
import { z } from "zod";
import CycleCount, { initCycleCount } from "../../models/CycleCount";
import { useCreateCycleCount } from "../../queries/CycleCountQueries";

export const CreateCycleCountPage: React.FC = () => {
    const { t, snackbar } = useContext(LayoutContext);
    const navigate = useNavigate();

    const { Field, Subscribe, handleSubmit } = useForm<CycleCount, ZodValidator>({
        defaultValues: initCycleCount,
        validatorAdapter: zodValidator(),
        onSubmit: ({ value }) => mutate({
            ...value,
            id: newGuid(),
        }),
    });

    const { mutate } = useCreateCycleCount({
        onSuccess: data => {
            snackbar({ title: `${data.name} ${t("Created").toLowerCase()}` });
            navigate(`/cycle-counts/${data.id}`);
        }
    });

    const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit();
    };

    return (
        <Container sx={{ my: 2 }}>
            <Card variant="outlined">
                <CardHeader title={t("CreateCycleCount")} />
                <form onSubmit={handleOnSubmit}>
                    <CardContent>
                        <Grid2 container spacing={2}>
                            <Grid2 size={12}>
                                <Field name="name" validators={{ onChange: z.string().min(1) }}>
                                    {({ name, state, handleChange, handleBlur }) =>
                                        <TextField name={name} value={state.value} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                            label={t("Name")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} required />
                                    }
                                </Field>
                            </Grid2>
                        </Grid2>
                    </CardContent>

                    <CardActions sx={{ justifyContent: "end" }}>
                        <Subscribe selector={(state) => [state.canSubmit]}>
                            {([canSubmit]) => <Button type="submit" disabled={!canSubmit} variant="contained">{t("Submit")}</Button>}
                        </Subscribe>
                    </CardActions>
                </form>
            </Card>
        </Container>
    );
};