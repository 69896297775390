import CycleCountItem from "./CycleCountItem";
import { CycleCountStatus } from "./enums/CycleCountStatus";

export default interface CycleCount {
    id: string,
    name: string
    startDate: string | null,
    endDate: string | null,
    status: CycleCountStatus,
    items: CycleCountItem[],
}

export const initCycleCount: CycleCount = {
    id: "",
    name: "",
    startDate: null,
    endDate: null,
    status: CycleCountStatus.Started,
    items: [],
};