import { Autocomplete, Button, Menu, Stack, TextField, Typography } from "@mui/material";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { FormEvent, Fragment, useEffect, useRef, useState } from "react";
import { hasRole, Platform, useLayout } from "wcz-layout";
import { z } from "zod";
import Location from "../../models/Location";
import Material from "../../models/Material";
import { useGetLocations } from "../../queries/LocationQueries";
import MaterialType from "../../models/MaterialType";
import { MaterialState } from "../../models/enums/MaterialState";
import AuthPolicy from "../../utils/AuthPolicy";

interface MaterialReceiveButtonForm {
    location: Location | null;
}

interface MaterialReceiveButtonProps {
    material: Material;
    type: MaterialType | undefined;
}

export const MaterialReceiveButton: React.FC<MaterialReceiveButtonProps> = ({ material, type }) => {
    const { t } = useLayout();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const locationAutocompleteRef = useRef<HTMLInputElement>(null);

    const { data: locations } = useGetLocations({ enabled: open });

    const { Field, Subscribe, handleSubmit, reset } = useForm({
        defaultValues: { location: null } as MaterialReceiveButtonForm,
        validatorAdapter: zodValidator(),
        onSubmit: ({ value }) => console.log(value),
    });

    const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit();
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => { setAnchorEl(null); reset(); };

    useEffect(() => {
        if (open && Platform.isWindows)
            setTimeout(() => locationAutocompleteRef.current?.focus(), 100);
    }, [open]);

    if (type?.requireHandover && material.state === MaterialState.InUse && hasRole(AuthPolicy.Admin))
        return (
            <Fragment>
                <Button onClick={handleClick}>{t("Receive")}</Button>

                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    slotProps={{ paper: { sx: { overflow: "visible", mt: 1.5, }, }, }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                    <Typography variant="h6" sx={{ px: 2 }}>{t("Receive")}</Typography>
                    <form onSubmit={handleOnSubmit}>
                        <Stack spacing={2} sx={{ p: 2, width: { xs: 300, lg: 350 } }}>
                            <Field name="location" validators={{ onChange: z.object({}).required() }}>
                                {({ name, state, handleChange, handleBlur }) =>
                                    <Autocomplete
                                        value={state.value}
                                        options={locations}
                                        getOptionLabel={(option) => option.name}
                                        autoHighlight
                                        onChange={(_, value) => handleChange(value)}
                                        renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Location")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} required inputRef={locationAutocompleteRef} />}
                                    />
                                }
                            </Field>
                            <Subscribe selector={(state) => [state.canSubmit]}>
                                {([canSubmit]) => <Button type="submit" disabled={!canSubmit} variant="contained">{t("Submit")}</Button>}
                            </Subscribe>
                        </Stack>
                    </form>
                </Menu>
            </Fragment>
        );

    return null;
};