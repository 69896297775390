import { Add, Done, QrCode } from "@mui/icons-material";
import { Autocomplete, BottomNavigation, BottomNavigationAction, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, TextField } from "@mui/material";
import { Fragment, useCallback, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";
import { LayoutDialog, LayoutContext, newGuid } from "wcz-layout";
import CycleCount from "../../../models/CycleCount";
import CycleCountItem from "../../../models/CycleCountItem";
import { CycleCountItemStatus } from "../../../models/enums/CycleCountItemStatus";
import { CycleCountStatus } from "../../../models/enums/CycleCountStatus";
import { useCreateCycleCountItem, useUpdateCycleCount, useUpdateCycleCountItem } from "../../../queries/CycleCountQueries";
import { useGetMaterials } from "../../../queries/MaterialQueries";
import Location from "../../../models/Location";
import { DynamsoftScanner } from "../../common/DynamsoftScanner";

interface BottomPanelProps {
    cycleCount: CycleCount;
    selectedLocationId: string;
    setSelectedLocationId: (location: string) => void;
    setScannedValue: (value: string) => void;
    locations: Location[];
}

export const BottomPanel: React.FC<BottomPanelProps> = ({ cycleCount, selectedLocationId, setSelectedLocationId, setScannedValue, locations }) => {
    const { t, snackbar } = useContext(LayoutContext);
    const [scannerOpen, setScannerOpen] = useState<boolean>(false);
    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
    const [newItem, setNewItem] = useState<CycleCountItem>({} as CycleCountItem);
    const navigate = useNavigate();
    const [beep] = useSound(require("../../../utils/sounds/beep.mp3"));

    const toggleScanner = useCallback(() => setScannerOpen(!scannerOpen), [scannerOpen]);

    const { mutateAsync: updateCycleCountItemAsync } = useUpdateCycleCountItem(cycleCount.id, {
        onSuccess: () => beep()
    });

    const handleOnScan = useCallback((text: string) => {
        if (!selectedLocationId)
            return handleOnLocationScan(text);

        setScannedValue(text);

        const cycleCountItem = cycleCount.items?.find(item =>
            item.material?.serialNumber === text ||
            item.material?.fixAsset === text ||
            item.scannedValue === text
        );

        if (cycleCountItem) {
            if (cycleCountItem.material?.isHighValue)
                return updateCycleCountItemAsync({ ...cycleCountItem, foundQuantity: 1, scannedValue: text })
                    .then(() => scrollTo(cycleCountItem.id));
            else
                return scrollTo(`${cycleCountItem.id}-input-qty`, true);
        } else
            return handleOnLocationScan(text);
    }, [cycleCount, selectedLocationId, locations]);

    const { mutateAsync: createCycleCountItemAsync } = useCreateCycleCountItem({
        onSuccess: () => beep()
    });

    const handleOnLocationScan = useCallback((text: string) => {
        const cycleCountItem = cycleCount.items?.find(item => item.location.name === text);
        if (cycleCountItem)
            return setSelectedLocationId(cycleCountItem.location.id);
        else
            return createCycleCountItemAsync({ locationName: text, cycleCountId: cycleCount.id })
                .then(() => {
                    const locationId = locations.find(l => l.name === text)!.id;
                    setSelectedLocationId(locationId);
                })
                .catch(() => {
                    updateCycleCountItemAsync({
                        ...{} as CycleCountItem,
                        id: newGuid(),
                        scannedValue: text,
                        location: locations.find(l => l.id === selectedLocationId)!,
                        status: CycleCountItemStatus.New,
                        expectedQuantity: 0
                    });
                });
    }, [cycleCount, selectedLocationId, locations]);

    const scrollTo = useCallback((id: string, focus?: boolean) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "center" });
            if (focus)
                element.focus();
        }
    }, []);

    const toggleFinishDialog = useCallback(() => setConfirmOpen(!confirmOpen), [confirmOpen]);

    const { mutate: updateCycleCount } = useUpdateCycleCount({
        onSuccess: () => {
            navigate("/cycle-counts");
            snackbar({ title: t("Finished") });
        }
    });

    const finishCycleCount = useCallback(() => updateCycleCount({ ...cycleCount, status: CycleCountStatus.Finished }), [cycleCount]);

    const anyItemWaiting = useMemo(() => cycleCount.items?.some(item => item.status === CycleCountItemStatus.Waiting), [cycleCount]);

    const confirmFinishDialog = (
        <Dialog open={confirmOpen} onClose={toggleFinishDialog} fullWidth>
            <DialogTitle>
                {t("AreYouSureYouWantToFinish")}?
            </DialogTitle>
            <DialogActions>
                <Button onClick={toggleFinishDialog}>{t("Disagree")}</Button>
                <Button onClick={finishCycleCount} autoFocus>{t("Agree")}</Button>
            </DialogActions>
        </Dialog>
    );

    const initializeNewItem = useCallback(() => setNewItem({
        ...newItem,
        id: newGuid(),
        status: CycleCountItemStatus.New,
        expectedQuantity: 0,
        foundQuantity: 1,
        location: locations.find(l => l.id === selectedLocationId)!
    }), [newItem, cycleCount, selectedLocationId]);

    const clearNewItem = useCallback(() => setNewItem({} as CycleCountItem), []);

    const { data: materials = [] } = useGetMaterials({
        enabled: !!newItem.id
    });

    const addCycleCountItem = useCallback(() => updateCycleCountItemAsync(newItem).then(clearNewItem), [newItem]);

    const addCycleCountItemDialog = (
        <LayoutDialog open={!!newItem.id} onClose={clearNewItem} title={t("NewMaterial")}>
            <DialogContent>
                <Autocomplete
                    value={newItem.material}
                    options={materials}
                    getOptionLabel={o => o.fixAsset ?? o.imei ?? `${o.name} (${o.location?.name})`}
                    autoHighlight
                    onChange={(e, value) => setNewItem({ ...newItem, material: value ?? undefined })}
                    renderInput={(params) => <TextField {...params} variant="standard" fullWidth label={t("Material")} margin="dense" required />}
                />

                {newItem.material && !newItem.material.isHighValue &&
                    <TextField variant="standard" fullWidth label={t("StockQuantity")} margin="dense" value={newItem.foundQuantity ?? 0} onChange={e => setNewItem({ ...newItem, foundQuantity: Number(e.target.value) })} type="number" />
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={addCycleCountItem} autoFocus>{t("Submit")}</Button>
            </DialogActions>
        </LayoutDialog>
    );

    return (
        <Fragment>
            <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation showLabels>
                    {selectedLocationId && <BottomNavigationAction label={t("Add")} icon={<Add />} onClick={initializeNewItem} />}
                    {!!cycleCount.items?.length && !anyItemWaiting && <BottomNavigationAction label={t("Finish")} icon={<Done />} onClick={toggleFinishDialog} />}
                    <BottomNavigationAction label={t("Scan")} icon={<QrCode />} onClick={toggleScanner} />
                </BottomNavigation>
            </Paper>

            <DynamsoftScanner open={scannerOpen} setOpen={setScannerOpen} onScan={handleOnScan} disableAutoclose={!!selectedLocationId} />
            {confirmFinishDialog}
            {addCycleCountItemDialog}
        </Fragment>
    );
};