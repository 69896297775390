import { SwapHoriz } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { DataGridPremium, GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-premium";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GridToolbar, hasRole, useLayout } from "wcz-layout";
import { TransferType } from "../../models/enums/TransferType";
import Material from "../../models/Material";
import MaterialTransfer from "../../models/MaterialTransfer";
import { keeperGetter } from "../../utils/Helpers";
import { TransferDetailDialog } from "./TransferDetailDialog";
import AuthPolicy from "../../utils/AuthPolicy";

interface TransferDataGridProps {
    data: MaterialTransfer[];
    isFetching: boolean;
}

export const TransferDataGrid: React.FC<TransferDataGridProps> = ({ data, isFetching }) => {
    const { t, user } = useLayout();
    const [transferDetail, setTransferDetail] = useState<MaterialTransfer | null>(null);
    const navigate = useNavigate();

    const handleOnEmployeeClick = (employeeId: string | undefined) => () => employeeId && navigate(`/employees/${employeeId}`);
    const handleOnMaterialClick = (materialId: string) => () => navigate(`/materials/${materialId}`);

    const handleOnRowDoubleClick = (params: GridRowParams) => setTransferDetail(params.row);

    const columns: GridColDef<MaterialTransfer>[] = [
        {
            field: "actions", type: "actions", width: 50, getActions: ({ row }) => [
                <GridActionsCellItem key="material" label={t("Material")} icon={<SwapHoriz />} onClick={() => setTransferDetail(row)} disabled={!(hasRole(AuthPolicy.Admin) || user.employeeId === row.newKeeper?.id) || row.type === TransferType.Receive} />,
            ],
        },
        { field: "dateTime", headerName: t("Date"), width: 180, type: "dateTime", valueGetter: value => value && new Date(value) },
        {
            field: "createdBy", headerName: t("HandledBy"), width: 260, valueGetter: keeperGetter,
            renderCell: ({ value, row }) =>
                <Typography
                    variant="body2"
                    onClick={handleOnEmployeeClick(row.createdBy.id)}
                    sx={{ display: "flex", alignItems: "center", height: "100%", "&:hover": { textDecoration: "underline", cursor: "pointer" } }}
                >
                    {value}
                </Typography>
        },
        { field: "type", headerName: t("Type"), width: 150, type: "singleSelect", valueOptions: Object.values(TransferType) },
        {
            field: "material", headerName: t("Material"), width: 350, valueGetter: (value: Material) => value.name,
            renderCell: ({ value, row }) =>
                <Typography
                    variant="body2"
                    onClick={handleOnMaterialClick(row.material.id)}
                    sx={{ display: "flex", alignItems: "center", height: "100%", "&:hover": { textDecoration: "underline", cursor: "pointer" } }}
                >
                    {value}
                </Typography>
        },
        {
            field: "oldKeeper", headerName: t("OldKeeper"), width: 260, valueGetter: keeperGetter,
            renderCell: ({ value, row }) =>
                <Typography
                    variant="body2"
                    onClick={handleOnEmployeeClick(row.oldKeeper?.id)}
                    sx={{ display: "flex", alignItems: "center", height: "100%", "&:hover": { textDecoration: "underline", cursor: "pointer" } }}
                >
                    {value}
                </Typography>
        },
        {
            field: "newKeeper", headerName: t("NewKeeper"), width: 260, valueGetter: keeperGetter,
            renderCell: ({ value, row }) =>
                <Typography
                    variant="body2"
                    onClick={handleOnEmployeeClick(row.newKeeper?.id)}
                    sx={{ display: "flex", alignItems: "center", height: "100%", "&:hover": { textDecoration: "underline", cursor: "pointer" } }}
                >
                    {value}
                </Typography>
        },
    ];

    return (
        <Fragment>
            <DataGridPremium
                rows={data}
                columns={columns}
                slots={{ toolbar: GridToolbar }}
                onRowDoubleClick={handleOnRowDoubleClick}
                loading={isFetching}
                ignoreDiacritics
            />

            <TransferDetailDialog transfer={transferDetail} setTransfer={setTransferDetail} />
        </Fragment>
    );
};