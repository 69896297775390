import { KeyboardReturn } from "@mui/icons-material";
import { Autocomplete, Button, Menu, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid-premium";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { FormEvent, Fragment, useState } from "react";
import { hasRole, newGuid, useLayout } from "wcz-layout";
import { z } from "zod";
import Employee from "../../models/Employee";
import Location from "../../models/Location";
import { EmployeeStatus } from "../../models/enums/EmployeeStatus";
import { useGetEmployees } from "../../queries/EmployeeQueries";
import { useGetLocations } from "../../queries/LocationQueries";
import Material from "../../models/Material";
import { useCreateHandover } from "../../queries/HandoverQueries";
import { TransferType } from "../../models/enums/TransferType";
import Cart from "../../models/Cart";
import { useCreateCart, useCreateCartItem, useGetCarts } from "../../queries/CartQueries";
import AuthPolicy from "../../utils/AuthPolicy";

interface MaterialBatchReceiveButtonForm {
    type: "location" | "employee";
    location: Location | null;
    employee: Employee | null;
}

interface MaterialBatchReceiveButtonProps {
    rowSelectionModel: GridRowSelectionModel;
    data: Material[];
}

export const MaterialBatchReceiveButton: React.FC<MaterialBatchReceiveButtonProps> = ({ rowSelectionModel, data }) => {
    const { t } = useLayout();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const { data: locations } = useGetLocations({ enabled: open });
    const { data: employees } = useGetEmployees({ enabled: open }, { status: EmployeeStatus.Active });
    const { data: carts } = useGetCarts({ enabled: open });

    const { Field, Subscribe, handleSubmit, useStore, reset } = useForm({
        defaultValues: { type: "location", location: null, employee: null } as MaterialBatchReceiveButtonForm,
        validatorAdapter: zodValidator(),
        onSubmit: ({ value }) => receiveMaterials(value),
    });

    const store = useStore(state => state.values);

    const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit();
    };

    const { mutateAsync: createHandover } = useCreateHandover();
    const { mutateAsync: createCart } = useCreateCart();
    const { mutateAsync: createCartItem } = useCreateCartItem();

    const receiveMaterials = (value: MaterialBatchReceiveButtonForm) => {
        const materials = data.filter((material) => rowSelectionModel.includes(material.id));
        const createdHandovers = materials.map(material =>
            createHandover({
                id: newGuid(),
                type: TransferType.Receive,
                keeper: material.keeper!,
                created: null,
                items: [{
                    id: newGuid(),
                    material: { ...material, location: value.location }
                }],
            })
        );

        Promise.all(createdHandovers).then(() => {
            if (value.employee) {
                const cart: Cart | undefined = carts.find(c => c.type === TransferType.Delivery && c.employee?.id === value.employee?.id);
                if (cart) {
                    const createdCartItems = materials.map(material => createCartItem({ cartId: cart.id, material: material, id: newGuid() }));
                    Promise.all(createdCartItems).then(() => handleClose());
                } else {
                    createCart({
                        id: newGuid(),
                        type: TransferType.Delivery,
                        employee: value.employee,
                        items: materials.map(material => ({ id: newGuid(), material: material }))
                    }).then(() => handleClose());
                }
            } else {
                handleClose();
            }
        });
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => { setAnchorEl(null); reset(); };

    return (
        <Fragment>
            {hasRole(AuthPolicy.Admin) && <Button startIcon={<KeyboardReturn />} sx={{ display: rowSelectionModel.length ? "inherit" : "none" }} onClick={handleClick}>{t("Receive")}</Button>}

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                slotProps={{ paper: { sx: { overflow: "visible", mt: 1.5, }, }, }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <Typography variant="h6" sx={{ px: 2 }}>{t("Receive")}</Typography>
                <form onSubmit={handleOnSubmit}>
                    <Field name="type">
                        {({ state, handleChange, form }) =>
                            <Tabs value={state.value} centered onChange={(e, value) => {
                                handleChange(value);
                                if (value === "location") form.setFieldValue("employee", null);
                                else form.setFieldValue("location", null);
                            }}>
                                <Tab label={t("Location")} value="location" />
                                <Tab label={t("Employee")} value="employee" />
                            </Tabs>
                        }
                    </Field>
                    <Stack spacing={2} sx={{ p: 2, width: { xs: 300, lg: 350 } }}>

                        {store.type === "location" &&
                            <Field name="location" validators={{
                                onChange: ({ value, fieldApi }) => {
                                    if (fieldApi.form.getFieldValue("type") === "location") {
                                        const { success, error } = z.object({}).required().safeParse(value);
                                        if (!success) return error.issues[0].message;
                                    }
                                },
                                onChangeListenTo: ["type"]
                            }}>
                                {({ name, state, handleChange, handleBlur, form }) =>
                                    <Autocomplete
                                        value={state.value}
                                        options={locations}
                                        getOptionLabel={(option) => option.name}
                                        autoHighlight
                                        onChange={(_, value) => handleChange(value)}
                                        renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Location")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} required={form.getFieldValue("type") === "location"} />}

                                    />
                                }
                            </Field>
                        }

                        {store.type === "employee" &&
                            <Field name="employee" validators={{
                                onChange: ({ value, fieldApi }) => {
                                    if (fieldApi.form.getFieldValue("type") === "employee") {
                                        const { success, error } = z.object({}).required().safeParse(value);
                                        if (!success) return error.issues[0].message;
                                    }
                                },
                                onChangeListenTo: ["type"]
                            }}>
                                {({ name, state, handleChange, handleBlur, form }) =>
                                    <Autocomplete
                                        value={state.value}
                                        options={employees}
                                        getOptionLabel={(option) => `${option.firstName} ${option.lastName} (${option.id})`}
                                        autoHighlight
                                        onChange={(_, value) => handleChange(value)}
                                        renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Employee")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} required={form.getFieldValue("type") === "employee"} />}

                                    />
                                }
                            </Field>
                        }
                        <Subscribe selector={(state) => [state.canSubmit]}>
                            {([canSubmit]) => <Button type="submit" disabled={!canSubmit} variant="contained">{t("Submit")}</Button>}
                        </Subscribe>
                    </Stack>
                </form>
            </Menu>
        </Fragment>
    );
};