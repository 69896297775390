export default interface Location {
    id: string,
    name: string,
    building: string | null,
    equipmentRoom: string | null,
    rack: string | null,
    shelf: string | null,
    box: string | null;
    isWarehouse: boolean,
    remark: string | null
}

export const initLocation: Location = {
    id: "",
    name: "",
    building: null,
    equipmentRoom: null,
    rack: null,
    shelf: null,
    box: null,
    isWarehouse: false,
    remark: null
}