import { Person } from "@mui/icons-material";
import { Card, CardActionArea, CardContent, CardHeader, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { useLayout } from "wcz-layout";
import { useGetEmployee } from "../../../queries/EmployeeQueries";
import moment from "moment";
import { EmployeeStatus } from "../../../models/enums/EmployeeStatus";

export const MeCard: React.FC = () => {
    const { t, user } = useLayout();
    const navigate = useNavigate();

    const { data: employee } = useGetEmployee(user.employeeId, {
        enabled: !!user.employeeId,
    });

    return (
        <Card variant="outlined">
            <CardActionArea onClick={() => navigate("/me")} sx={theme => ({ bgcolor: grey[theme.palette.mode === "dark" ? 900 : 100] })}>
                <CardHeader title={`${employee.firstName} ${employee.lastName} (${employee.id})`} subheader={employee.status} avatar={<Person color={employee.status === EmployeeStatus.Active ? "success" : "error"} />} />
            </CardActionArea>
            <CardContent>
                <Typography variant="body2"><b>{t("Department")}:</b> {employee.department}</Typography>
                {employee.company && <Typography variant="body2"><b>{t("Company")}:</b> {employee.company}</Typography>}
                {employee.terminationFutureDate && <Typography variant="body2"><b>{t("TerminationDate")}:</b> {moment(employee.terminationFutureDate).formatDate()}</Typography>}
            </CardContent>
        </Card>
    );
};