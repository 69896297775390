import { Box, Button, Checkbox, FormControlLabel, FormGroup, Menu, Stack, TextField, Typography } from "@mui/material";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { FormEvent, Fragment, useEffect, useRef, useState } from "react";
import { hasRole, Platform, useLayout } from "wcz-layout";
import { z } from "zod";
import Material from "../../models/Material";
import MaterialType from "../../models/MaterialType";
import AuthPolicy from "../../utils/AuthPolicy";
import { useUpdateMaterial } from "../../queries/MaterialQueries";
import { DynamsoftScanner } from "../common/DynamsoftScanner";

interface MaterialRegistrationButtonProps {
    material: Material;
    type: MaterialType | undefined;
}

export const MaterialRegistrationButton: React.FC<MaterialRegistrationButtonProps> = ({ material, type }) => {
    const { t } = useLayout();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [scannerOpen, setScannerOpen] = useState<boolean>(false);
    const open = Boolean(anchorEl);
    const registrationIdRef = useRef<HTMLInputElement>(null);

    const { Field, Subscribe, handleSubmit, reset, setFieldValue } = useForm({
        defaultValues: material,
        validatorAdapter: zodValidator(),
        onSubmit: ({ value }) => handleMutation(value),
    });

    const handleMutation = (value: Material) => {
        const registration = value.registration?.id ? value.registration : null;
        if (registration !== null) {
            registration.wcz = value.registration?.wcz ?? false;
            registration.wscz = value.registration?.wscz ?? false;
        }

        mutate({ ...value, registration: registration });
    };

    const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit();
    };

    const { mutate } = useUpdateMaterial({
        onSuccess: () => handleClose()
    });

    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => { setAnchorEl(null); reset(); };

    useEffect(() => {
        if (open && Platform.isWindows)
            setTimeout(() => registrationIdRef.current?.select(), 100);
        else if (open)
            setScannerOpen(true);
    }, [open]);

    const handleOnScan = (value: string) => {
        setFieldValue("registration.id", value);
    };

    if (type?.requireHandover && type?.hasDeviceId && material.keeper !== null && hasRole(AuthPolicy.Admin))
        return (
            <Fragment>
                <Button onClick={handleClick}>{t("Registration")}</Button>

                <Menu
                    anchorEl={anchorEl}
                    open={open && !scannerOpen}
                    onClose={handleClose}
                    slotProps={{ paper: { sx: { overflow: "visible", mt: 1.5, }, }, }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                    <Typography variant="h6" sx={{ px: 2 }}>{t("Registration")}</Typography>
                    <form onSubmit={handleOnSubmit}>
                        <Stack spacing={2} sx={{ p: 2, width: { xs: 300, lg: 350 } }}>
                            <Field name="registration.id" validators={{ onChange: z.string().max(50).nullable() }}>
                                {({ name, state, handleChange, handleBlur }) =>
                                    <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                        label="ID" error={!!state.meta.errors.length} helperText={state.meta.errors[0]} inputRef={registrationIdRef} />
                                }
                            </Field>
                            <Box>
                                <Field name="registration.wcz">
                                    {({ name, state, handleChange, handleBlur }) =>
                                        <FormGroup>
                                            <FormControlLabel name={name} control={<Checkbox checked={state.value ?? false} onBlur={handleBlur} />} label="WCZ" onChange={(e, checked) => handleChange(checked)} />
                                        </FormGroup>
                                    }
                                </Field>
                                <Field name="registration.wscz">
                                    {({ name, state, handleChange, handleBlur }) =>
                                        <FormGroup>
                                            <FormControlLabel name={name} control={<Checkbox checked={state.value ?? false} onBlur={handleBlur} />} label="WSCZ" onChange={(e, checked) => handleChange(checked)} />
                                        </FormGroup>
                                    }
                                </Field>
                            </Box>
                            <Subscribe selector={(state) => [state.canSubmit]}>
                                {([canSubmit]) => <Button type="submit" disabled={!canSubmit} variant="contained">{t("Submit")}</Button>}
                            </Subscribe>
                        </Stack>
                    </form>
                </Menu>

                <DynamsoftScanner open={scannerOpen} setOpen={setScannerOpen} onScan={handleOnScan} />
            </Fragment>
        );

    return null;
};