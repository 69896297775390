import { History, Laptop, SwapHoriz, VerifiedUser } from "@mui/icons-material";
import { Autocomplete, Button, Card, CardActions, CardContent, CardHeader, Chip, Container, Grid2, Tab, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { useForm } from "@tanstack/react-form";
import { ZodValidator, zodValidator } from "@tanstack/zod-form-adapter";
import moment from "moment";
import { FormEvent, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { hasRole, LayoutContext, TableContainer } from "wcz-layout";
import { z } from "zod";
import { TabPanel } from "../../components/common/TabPanel";
import { VerticalTabs } from "../../components/common/VerticalTabs";
import { MaterialHandoverButton } from "../../components/material/MaterialHandoverButton";
import { MaterialReceiveButton } from "../../components/material/MaterialReceiveButton";
import { MaterialRegistrationButton } from "../../components/material/MaterialRegistrationButton";
import { TrailDataGrid } from "../../components/trail/TrailDataGrid";
import { TransferDataGrid } from "../../components/transfer/TransferDataGrid";
import PaginationFilter from "../../models/base/PaginationFilter";
import { MaterialState } from "../../models/enums/MaterialState";
import Material from "../../models/Material";
import { useDeleteMaterial, useGetMaterial, useGetMaterialOptions, useGetMaterialTransferSearch, useUpdateMaterial } from "../../queries/MaterialQueries";
import { useGetTrails } from "../../queries/TrailQueries";
import AuthPolicy from "../../utils/AuthPolicy";

const materialTransferFilter = (materialId: string): PaginationFilter => {
    return {
        advancedFilter: {
            logic: "or",
            filters: [{ field: "material.id", operator: "eq", value: materialId }]
        },
        orderBy: ["dateTime desc"]
    };
};

export const MaterialDetailPage: React.FC = () => {
    const { id } = useParams();
    const { t, snackbar } = useContext(LayoutContext);
    const [tab, setTab] = useState(0);
    const navigate = useNavigate();
    const isAdmin = hasRole(AuthPolicy.Admin);

    const { data } = useGetMaterial(id!, {
        enabled: tab === 0,
        refetchOnWindowFocus: false,
    });

    const { data: options } = useGetMaterialOptions({ enabled: tab === 0 });

    const type = options.types.find(t => t.name === data.type);

    const { data: materialTransferSearch } = useGetMaterialTransferSearch(materialTransferFilter(data.id), { enabled: !!data.id && tab === 1 });

    const { data: trails, isFetching: isFetchingTrails } = useGetTrails({ enabled: !!data.id && tab === 2 }, data.id);

    const { Field, Subscribe, handleSubmit, setFieldValue } = useForm<Material, ZodValidator>({
        defaultValues: data,
        validatorAdapter: zodValidator(),
        onSubmit: ({ value }) => mutate(value),
    });

    const { mutate } = useUpdateMaterial({
        onSuccess: () => snackbar({ title: `${data.name} ${t("Updated").toLowerCase()}` }),
    });

    const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit();
    };

    const { mutate: deleteMaterial } = useDeleteMaterial({
        onSuccess: () => {
            navigate("/materials");
            snackbar({ title: `${data.name} ${t("Deleted").toLowerCase()}` });
        }
    });

    const handleDeleteMaterial = () => {
        if (window.confirm(t("AreYouSureToDelete")))
            deleteMaterial(data.id);
    };

    const handleOnFixAssetChange = (value: string | null) => {
        if (value?.length === 5 && value.endsWith("9")) {
            const companyCode: string = value.substring(0, 4).toUpperCase();
            if (options.highestFixAsset)
                setFieldValue("fixAsset", `${companyCode}${options.highestFixAsset + 1}`);
        }
    };

    const handleOnItIdFocus = (value: string | null) => () => {
        if (options.highestItId && !value) {
            const increasedItId = options.highestItId + 1;
            const paddedItId = increasedItId.toString().padStart(5, "0");
            setFieldValue("itId", `IT${paddedItId}`);
        }
    };

    return (
        <VerticalTabs value={tab} onChange={setTab} tabs={[
            <Tab icon={<Laptop />} label={t("Material")} value={0} key={0} />,
            <Tab icon={<SwapHoriz />} label={t("Transfers")} value={1} key={1} />,
            <Tab icon={<History />} label={t("Logs")} value={2} key={2} disabled={!isAdmin} />
        ]}>
            <TabPanel value={tab} index={0}>
                <Container sx={{ my: 2 }}>
                    <Card variant="outlined">
                        <CardHeader title={data.name} subheader={data.keeper && `${data.keeper.firstName} ${data.keeper.lastName} (${data.keeper.id})`} action={(type?.hasDeviceId && <Chip label={t(data.registration ? "Registered" : "Unregistered")} color={data.registration ? "success" : "default"} icon={data.registration ? <VerifiedUser fontSize="small" /> : undefined} />)} />
                        <form onSubmit={handleOnSubmit}>
                            <CardContent>
                                <Grid2 container spacing={2}>
                                    <Grid2 size={12}>
                                        <Field name="type" validators={{ onChange: z.string().min(1).max(50) }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <Autocomplete
                                                    value={state.value}
                                                    options={options.types.map(t => t.name)}
                                                    autoHighlight
                                                    onChange={(_, value) => handleChange(value!)}
                                                    disabled={!isAdmin}
                                                    renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Type")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} required />}
                                                />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={type?.hasSerialNumber ? undefined : "none"}>
                                        <Field name="serialNumber" validators={{ onChange: z.string().max(50).nullable() }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                                    label="SN" error={!!state.meta.errors.length} helperText={state.meta.errors[0]} disabled={!isAdmin} />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={type?.hasFixAsset ? undefined : "none"}>
                                        <Field name="fixAsset" validators={{
                                            onChange: ({ value, fieldApi }) => {
                                                if (fieldApi.form.getFieldValue("fixAsset")?.length) {
                                                    handleOnFixAssetChange(value);
                                                    const { success, error } = z.string().length(12).safeParse(value);
                                                    if (!success) return error.issues[0].message;
                                                }
                                            },
                                            onChangeListenTo: ["fixAsset"]
                                        }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                                    label="FixAsset" error={!!state.meta.errors.length} helperText={state.meta.errors[0]} disabled={!isAdmin} />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={type?.hasImei ? undefined : "none"}>
                                        <Field name="imei" validators={{ onChange: z.string().max(50).nullable() }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                                    label="IMEI" error={!!state.meta.errors.length} helperText={state.meta.errors[0]} disabled={!isAdmin} />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={!type?.hasManufacturer ? "none" : undefined}>
                                        <Field name="manufacturer" validators={{ onChange: z.string().max(50).nullable() }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <Autocomplete
                                                    value={state.value}
                                                    options={options.manufacturers}
                                                    autoHighlight
                                                    onChange={(_, value) => handleChange(value)}
                                                    disabled={!isAdmin}
                                                    renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Manufacturer")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} />}
                                                />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={!type?.hasModel ? "none" : undefined}>
                                        <Field name="model" validators={{ onChange: z.string().max(100).nullable() }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <Autocomplete
                                                    value={state.value}
                                                    options={options.models}
                                                    autoHighlight
                                                    onChange={(_, value) => handleChange(value)}
                                                    disabled={!isAdmin}
                                                    renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Model")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} />}
                                                />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={!type?.hasState ? "none" : undefined}>
                                        <Field name="state" validators={{ onChange: z.string().max(50).nullable() }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <Autocomplete
                                                    value={state.value}
                                                    options={Object.values(MaterialState)}
                                                    autoHighlight
                                                    onChange={(_, value) => handleChange(value!)}
                                                    disabled={!isAdmin}
                                                    renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("State")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} />}
                                                />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={!type?.hasLocation ? "none" : undefined}>
                                        <Field name="location">
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <Autocomplete
                                                    value={state.value}
                                                    options={options.locations}
                                                    getOptionLabel={option => option.name}
                                                    autoHighlight
                                                    onChange={(_, value) => handleChange(value)}
                                                    disabled={!isAdmin}
                                                    renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Location")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} />}
                                                />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={type?.hasDepartment ? undefined : "none"}>
                                        <Field name="department" validators={{ onChange: z.string().max(50).nullable() }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                                    label={t("Department")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} disabled={!isAdmin} />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={type?.hasPartNumber ? undefined : "none"}>
                                        <Field name="partNumber" validators={{ onChange: z.string().max(50).nullable() }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                                    label="PN" error={!!state.meta.errors.length} helperText={state.meta.errors[0]} disabled={!isAdmin} />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={!type?.hasCompany ? "none" : undefined}>
                                        <Field name="company" validators={{ onChange: z.string().max(20).nullable() }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <Autocomplete
                                                    value={state.value ?? ""}
                                                    options={options.companies}
                                                    autoHighlight
                                                    onChange={(_, value) => handleChange(value)}
                                                    disabled={!isAdmin}
                                                    renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Company")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} />}
                                                />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={!type?.hasOrderDate ? "none" : undefined}>
                                        <Field name="orderDate">
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <DatePicker value={state.value ? moment(state.value) : null} onChange={newValue => handleChange(moment(newValue).format())} label={t("EffectiveTo")} disabled={!isAdmin}
                                                    slotProps={{ textField: { name: name, size: "small", fullWidth: true, onBlur: handleBlur, error: !!state.meta.errors.length, helperText: state.meta.errors[0] } }} />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={!type?.hasMonthsOfWarranty ? "none" : undefined}>
                                        <Field name="monthsOfWarranty" validators={{ onChange: z.number().positive().nullable() }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(Number(e.target.value))} onBlur={handleBlur} fullWidth size="small" type="number"
                                                    label={t("Warranty")} error={!!state.meta.errors?.length} helperText={state.meta.errors?.[0]} slotProps={{ input: { endAdornment: t("Months") } }} disabled={!isAdmin} />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={!type?.hasUnderRepairTo ? "none" : undefined}>
                                        <Field name="underRepairTo">
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <DatePicker value={state.value ? moment(state.value) : null} onChange={newValue => handleChange(moment(newValue).format())} label={t("UnderRepairTo")} disabled={!isAdmin}
                                                    slotProps={{ textField: { name: name, size: "small", fullWidth: true, onBlur: handleBlur, error: !!state.meta.errors.length, helperText: state.meta.errors[0] } }} />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={type?.hasItId ? undefined : "none"}>
                                        <Field name="itId" validators={{
                                            onChange: ({ value, fieldApi }) => {
                                                if (fieldApi.form.getFieldValue("itId")?.length) {
                                                    const { success, error } = z.string().length(7).safeParse(value);
                                                    if (!success) return error.issues[0].message;
                                                }
                                            }
                                        }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                                    label="IT ID" error={!!state.meta.errors.length} helperText={state.meta.errors[0]} onFocus={handleOnItIdFocus(state.value)} disabled={!isAdmin} />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={!type?.hasStockQuantity ? "none" : undefined}>
                                        <Field name="stockQuantity" validators={{ onChange: z.number().nonnegative().nullable() }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(Number(e.target.value))} onBlur={handleBlur} fullWidth size="small" type="number"
                                                    label={t("StockQuantity")} error={!!state.meta.errors?.length} helperText={state.meta.errors?.[0]} slotProps={{ input: { endAdornment: t("Pcs").toLocaleLowerCase() } }} disabled={!isAdmin} />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={!type?.hasSafetyStock ? "none" : undefined}>
                                        <Field name="safetyStock" validators={{ onChange: z.number().positive().nullable() }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(Number(e.target.value))} onBlur={handleBlur} fullWidth size="small" type="number"
                                                    label={t("SafetyStock")} error={!!state.meta.errors?.length} helperText={state.meta.errors?.[0]} slotProps={{ input: { endAdornment: t("Pcs").toLocaleLowerCase() } }} disabled={!isAdmin} />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={!type?.hasPrice ? "none" : undefined}>
                                        <Field name="price" validators={{ onChange: z.number().nonnegative().nullable() }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(Number(e.target.value))} onBlur={handleBlur} fullWidth size="small" type="number"
                                                    label={t("Price")} error={!!state.meta.errors?.length} helperText={state.meta.errors?.[0]} disabled={!isAdmin} />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={!type?.hasCurrency ? "none" : undefined}>
                                        <Field name="currency" validators={{ onChange: z.string().max(10).nullable() }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <Autocomplete
                                                    value={state.value ?? ""}
                                                    options={options.currencies}
                                                    autoHighlight
                                                    onChange={(_, value) => handleChange(value)}
                                                    disabled={!isAdmin}
                                                    renderInput={(params) => <TextField {...params} name={name} onBlur={handleBlur} fullWidth size="small" label={t("Currency")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} />}
                                                />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={type?.hasHostname ? undefined : "none"}>
                                        <Field name="hostname" validators={{ onChange: z.string().max(255).nullable() }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                                    label={t("Hostname")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} disabled={!isAdmin} />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={type?.hasRemark ? undefined : "none"}>
                                        <Field name="remark" validators={{ onChange: z.string().max(255).nullable() }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                                    label={t("Remark")} error={!!state.meta.errors.length} helperText={state.meta.errors[0]} disabled={!isAdmin} />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={type?.hasIp ? undefined : "none"}>
                                        <Field name="ip" validators={{ onChange: z.string().max(20).nullable() }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                                    label="IP" error={!!state.meta.errors.length} helperText={state.meta.errors[0]} disabled={!isAdmin} />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={type?.hasMacLan ? undefined : "none"}>
                                        <Field name="macLan" validators={{ onChange: z.string().max(100).nullable() }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                                    label="MAC LAN" error={!!state.meta.errors.length} helperText={state.meta.errors[0]} disabled={!isAdmin} />
                                            }
                                        </Field>
                                    </Grid2>
                                    <Grid2 size={12} display={type?.hasMacWlan ? undefined : "none"}>
                                        <Field name="macWlan" validators={{ onChange: z.string().max(100).nullable() }}>
                                            {({ name, state, handleChange, handleBlur }) =>
                                                <TextField name={name} value={state.value ?? ""} onChange={(e) => handleChange(e.target.value)} onBlur={handleBlur} fullWidth size="small"
                                                    label="MAC WLAN" error={!!state.meta.errors.length} helperText={state.meta.errors[0]} disabled={!isAdmin} />
                                            }
                                        </Field>
                                    </Grid2>
                                </Grid2>
                            </CardContent>

                            {isAdmin &&
                                <CardActions sx={{ justifyContent: "end", flexWrap: "wrap", }} >
                                    <Button color="error" onClick={handleDeleteMaterial}>{t("Delete")}</Button>
                                    <MaterialRegistrationButton material={data} type={type} />
                                    <MaterialHandoverButton material={data} type={type} />
                                    <MaterialReceiveButton material={data} type={type} />
                                    <Subscribe selector={(state) => [state.canSubmit]}>
                                        {([canSubmit]) => <Button type="submit" disabled={!canSubmit} variant="contained">{t("Submit")}</Button>}
                                    </Subscribe>
                                </CardActions>
                            }
                        </form>
                    </Card>
                </Container>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <TableContainer>
                    <TransferDataGrid data={materialTransferSearch.data} isFetching={isFetchingTrails} />
                </TableContainer>
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <TableContainer>
                    <TrailDataGrid data={trails} isFetching={isFetchingTrails} />
                </TableContainer>
            </TabPanel>
        </VerticalTabs>
    );
};