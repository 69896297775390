import { useParams } from "react-router-dom";
import { FinishedCycleCount } from "../../components/cycleCount/FinishedCycleCount";
import { StartedCycleCount } from "../../components/cycleCount/StartedCycleCount";
import { CycleCountStatus } from "../../models/enums/CycleCountStatus";
import { useGetCycleCount } from "../../queries/CycleCountQueries";

export default function CycleCountDetail() {
    const { id } = useParams();

    const { data: cycleCount } = useGetCycleCount(id!, { enabled: !!id });

    if (cycleCount.status === CycleCountStatus.Started)
        return <StartedCycleCount cycleCount={cycleCount} />;

    if (cycleCount.status === CycleCountStatus.Finished || cycleCount.status === CycleCountStatus.Confirmed)
        return <FinishedCycleCount cycleCount={cycleCount} />;

    return null;
}