import Employee from "./Employee";
import { MaterialState } from "./enums/MaterialState";
import Location from "./Location";
import MaterialRegistration from "./MaterialRegistration";

export default interface Material {
    id: string,
    name: string | null,
    type: string,
    manufacturer: string | null,
    partNumber: string | null,
    serialNumber: string | null,
    fixAsset: string | null,
    model: string | null,
    imei: string | null,
    orderDate: string | null,
    monthsOfWarranty: number | null,
    state: MaterialState,
    underRepairTo: string | null,
    price: number | null,
    currency: string | null,
    keeper: Employee | null,
    itId: string | null,
    stockQuantity: number | null,
    safetyStock: number | null,
    location: Location | null,
    hostname: string | null,
    remark: string | null,
    company?: string | null,
    ip: string | null,
    macLan: string | null,
    macWlan: string | null,
    department: string | null,
    registration: MaterialRegistration | null,
    isHighValue: boolean
}

export const initMaterial: Material = {
    id: "",
    name: null,
    type: "",
    manufacturer: null,
    partNumber: null,
    serialNumber: null,
    fixAsset: null,
    model: null,
    imei: null,
    orderDate: null,
    monthsOfWarranty: null,
    state: MaterialState.New,
    underRepairTo: null,
    price: null,
    currency: null,
    keeper: null,
    itId: null,
    stockQuantity: null,
    safetyStock: null,
    location: null,
    hostname: null,
    remark: null,
    company: null,
    ip: null,
    macLan: null,
    macWlan: null,
    department: null,
    registration: null,
    isHighValue: false
}