import { Box, DialogContent, Divider, Typography } from "@mui/material";
import moment from "moment";
import { FC } from "react";
import { FileViewer } from "wcz-file";
import { LayoutDialog, useLayout } from "wcz-layout";
import { MaterialState } from "../../models/enums/MaterialState";
import MaterialTransfer from "../../models/MaterialTransfer";

interface TransferDetailDialogProps {
    transfer: MaterialTransfer | null;
    setTransfer: (transfer: MaterialTransfer | null) => void;
}

export const TransferDetailDialog: FC<TransferDetailDialogProps> = ({ transfer, setTransfer }) => {
    const { t } = useLayout();
    const open = !!transfer;

    const onClose = () => setTransfer(null);

    return (
        <LayoutDialog open={open} onClose={onClose} title={t("TransferDetail")} color="primary" maxWidth="md">
            <DialogContent>
                <Box sx={{ border: "1px solid", p: 1, textAlign: "center" }}>
                    <Typography gutterBottom>{t("SignaturePartOne", { name: `${transfer?.newKeeper?.firstName} ${transfer?.newKeeper?.lastName}` })}</Typography>
                    <Typography gutterBottom><b>{transfer?.material.name}</b></Typography>

                    <Divider sx={{ mb: 2 }} />

                    {transfer?.material.state !== MaterialState.Sold && <Typography>{t("SignaturePartTwo")}</Typography>}
                    <FileViewer hideActionsMenu subId={transfer?.handover.id} sx={{ border: "1px solid" }} />
                    <Typography><b>{t("Handover")}: </b>{moment(transfer?.dateTime).formatDateTime()}</Typography>
                </Box>
            </DialogContent>
        </LayoutDialog>
    );
};