import { DataGridPremium, GridActionsCellItem, GridColDef, GridRowId } from "@mui/x-data-grid-premium";
import { GridToolbar, TableContainer, useLayout } from "wcz-layout";
import MaterialType from "../../models/MaterialType";
import { useCreateMaterialType, useDeleteMaterialType, useGetMaterialTypes, useUpdateMaterialType } from "../../queries/MaterialTypeQueries";
import { Delete } from "@mui/icons-material";

export const MaterialTypesPage: React.FC = () => {
    const { t } = useLayout();

    const { data, isFetching } = useGetMaterialTypes();

    const { mutateAsync: createMaterialType } = useCreateMaterialType();
    const { mutateAsync: updateMaterialType } = useUpdateMaterialType();
    const { mutate: deleteMaterialType } = useDeleteMaterialType();

    const handleProcessRowUpdate = async (row: MaterialType): Promise<MaterialType> => {
        if (row.isNew)
            await createMaterialType(row);
        else
            await updateMaterialType(row);

        return { ...row, isNew: false };
    };

    const handleDeleteMaterialType = (id: GridRowId) => () => {
        if (window.confirm(t("AreYouSure"))) {
            deleteMaterialType(id);
        }
    };

    const columns: GridColDef<MaterialType>[] = [
        { field: "name", headerName: t("Name"), editable: true, width: 200, },
        { field: "hasManufacturer", headerName: t("Manufacturer"), editable: true, width: 120, type: "boolean" },
        { field: "hasPartNumber", headerName: "PN", editable: true, width: 120, type: "boolean" },
        { field: "hasSerialNumber", headerName: "SN", editable: true, width: 120, type: "boolean" },
        { field: "hasFixAsset", headerName: "Fix Asset", editable: true, width: 120, type: "boolean" },
        { field: "hasModel", headerName: "Model", editable: true, width: 120, type: "boolean" },
        { field: "hasImei", headerName: "IMEI", editable: true, width: 120, type: "boolean" },
        { field: "hasOrderDate", headerName: t("OrderDate"), editable: true, width: 120, type: "boolean" },
        { field: "hasMonthsOfWarranty", headerName: t("Warranty"), editable: true, width: 120, type: "boolean" },
        { field: "hasState", headerName: t("State"), editable: true, width: 120, type: "boolean" },
        { field: "hasUnderRepairTo", headerName: t("UnderRepairTo"), editable: true, width: 120, type: "boolean" },
        { field: "hasPrice", headerName: t("Price"), editable: true, width: 120, type: "boolean" },
        { field: "hasCurrency", headerName: t("Currency"), editable: true, width: 120, type: "boolean" },
        { field: "hasItId", headerName: "IT ID", editable: true, width: 120, type: "boolean" },
        { field: "hasStockQuantity", headerName: t("StockQuantity"), editable: true, width: 120, type: "boolean" },
        { field: "hasSafetyStock", headerName: t("SafetyStock"), editable: true, width: 120, type: "boolean" },
        { field: "hasLocation", headerName: t("Location"), editable: true, width: 120, type: "boolean" },
        { field: "hasDepartment", headerName: t("Department"), editable: true, width: 120, type: "boolean" },
        { field: "hasHostname", headerName: t("Hostname"), editable: true, width: 120, type: "boolean" },
        { field: "hasRemark", headerName: t("Remark"), editable: true, width: 120, type: "boolean" },
        { field: "hasCompany", headerName: t("Company"), editable: true, width: 120, type: "boolean" },
        { field: "requireHandover", headerName: t("Handover"), editable: true, width: 120, type: "boolean" },
        { field: "requireSignature", headerName: t("Signature"), editable: true, width: 120, type: "boolean" },
        { field: "hasIp", headerName: "IP", editable: true, width: 120, type: "boolean" },
        { field: "hasMacLan", headerName: "MAC LAN", editable: true, width: 120, type: "boolean" },
        { field: "hasMacWlan", headerName: "MAC WLAN", editable: true, width: 120, type: "boolean" },
        { field: "hasDeviceId", headerName: t("DeviceId"), editable: true, width: 120, type: "boolean" },
        { field: "requireLocation", headerName: t("RequireLocation"), editable: true, width: 150, type: "boolean" },
        {
            field: "actions", type: "actions", width: 50,
            getActions: ({ id }) => [<GridActionsCellItem key="remove" onClick={handleDeleteMaterialType(id)} label={t("Delete")} icon={<Delete />} showInMenu={false} />]
        },
    ];

    return (
        <TableContainer>
            <DataGridPremium
                rows={data}
                columns={columns}
                slots={{ toolbar: GridToolbar }}
                loading={isFetching}
                ignoreDiacritics
                editMode="row"
                processRowUpdate={handleProcessRowUpdate}
                initialState={{ pinnedColumns: { left: ["name"] } }}
            />
        </TableContainer>
    );
};