import { Container, Stack } from "@mui/material";
import React from "react";
import { AdvancedSearch } from "../components/homepage/AdvancedSearch";
import { NewButton } from "../components/homepage/NewButton";
import { hasRole } from "wcz-layout";
import AuthPolicy from "../utils/AuthPolicy";

export const HomePage: React.FC = () => {
    const isAdmin = hasRole(AuthPolicy.Admin);

    return (
        <Container sx={{ my: 2 }}>
            <Stack spacing={2}>
                {isAdmin && <NewButton />}
                <AdvancedSearch />
            </Stack>
        </Container>
    );
};