import { Check } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { DataGridPremium, gridClasses, GridColDef } from "@mui/x-data-grid-premium";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getContrastTextColor, GridToolbar, LayoutContext, TableContainer } from "wcz-layout";
import CycleCount from "../../models/CycleCount";
import CycleCountItem from "../../models/CycleCountItem";
import { CycleCountItemStatus } from "../../models/enums/CycleCountItemStatus";
import { CycleCountStatus } from "../../models/enums/CycleCountStatus";
import Location from "../../models/Location";
import Material from "../../models/Material";
import { useUpdateCycleCount } from "../../queries/CycleCountQueries";

interface FinishedCycleCountProps {
    cycleCount: CycleCount;
}

export const FinishedCycleCount: React.FC<FinishedCycleCountProps> = ({ cycleCount }) => {
    const { t, snackbar } = useContext(LayoutContext);
    const [updatedCycleCount, setUpdatedCycleCount] = useState<CycleCount>({} as CycleCount);
    const navigate = useNavigate();

    useEffect(() => setUpdatedCycleCount(cycleCount), [cycleCount]);

    const columns: GridColDef<CycleCountItem>[] = [
        { field: "scannedValue", headerName: t("ScannedValue"), width: 170, },
        { field: "location", headerName: t("Location"), width: 170, valueGetter: (value: Location) => value.name, },
        {
            field: "material", headerName: t("Material"), width: 400, valueGetter: (value: Material) => [value?.name, value?.fixAsset, value?.imei, value?.partNumber, value?.serialNumber],
            renderCell: ({ value }) => {
                return (
                    <Box sx={{ my: 1 }}>
                        {value[0] && <Typography>{value[0]}</Typography>}
                        {value[1] && <Typography variant="body2"><b>Fix Asset: </b>{value[1]}</Typography>}
                        {value[2] && <Typography variant="body2"><b>IMEI: </b>{value[2]}</Typography>}
                        {value[3] && <Typography variant="body2"><b>PN: </b>{value[3]}</Typography>}
                        {value[4] && <Typography variant="body2"><b>SN: </b>{value[4]}</Typography>}
                        {!value[0] && !value[1] && !value[2] && !value[3] && !value[4] && <Typography variant="body2">-</Typography>}
                    </Box>
                );
            }
        },
        { field: "status", headerName: t("Status"), width: 170, type: "singleSelect", valueOptions: Object.values(CycleCountItemStatus) },
        {
            field: "expectedQuantity", headerName: t("ExpectedQuantity"), width: 160, type: "number",
        },
        {
            field: "foundQuantity", headerName: t("FoundQuantity"), width: 160, type: "number",
        },
        {
            field: "confirmedQuantity", headerName: t("ConfirmedQuantity"), width: 160, type: "number",
            editable: true,// renderHeader: cycleCount.status === CycleCountStatus.Finished && EditableColumnHeader,
            renderCell: ({ value, row }) => value ?? <Typography sx={{ opacity: 0.3 }}>{row.foundQuantity}</Typography>
        },
    ];

    const { mutate: updateCycleCount } = useUpdateCycleCount({
        onSuccess: () => {
            snackbar({ title: t("CycleCountConfirmed") });
            navigate("/cycle-counts");
        }
    });

    const confirmCycleCount = () => updateCycleCount({
        ...updatedCycleCount,
        status: CycleCountStatus.Confirmed,
        items: updatedCycleCount.items?.map(d => ({ ...d, confirmedQuantity: d.confirmedQuantity ?? d.foundQuantity }))
    });

    const processRowUpdate = async (row: CycleCountItem) => {
        setUpdatedCycleCount({
            ...updatedCycleCount,
            items: updatedCycleCount.items?.map(i => i.id === row.id ? row : i)
        });

        return row;
    };

    return (
        <TableContainer sx={{
            [`.${gridClasses.cell}.warning`]: {
                backgroundColor: "warning.main",
                color: getContrastTextColor("warning.main")
            },
        }}>
            <DataGridPremium
                rows={updatedCycleCount.items ?? []}
                columns={columns}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        hideAddRecord: true, items: cycleCount.status === CycleCountStatus.Finished ? [{ title: t("Confirm"), onClick: confirmCycleCount, icon: <Check /> }] : undefined
                    }
                }}
                isCellEditable={params => {
                    switch (params.field) {
                        case "confirmedQuantity": return cycleCount.status === CycleCountStatus.Finished;
                        default: return false;
                    }
                }}
                editMode="cell"
                processRowUpdate={processRowUpdate}
                getRowHeight={() => "auto"}
                getCellClassName={(params) => {
                    if (params.row.status !== CycleCountItemStatus.Ok && ["expectedQuantity", "foundQuantity", "confirmedQuantity"].includes(params.field))
                        return "warning";
                    return "";
                }}
            />
        </TableContainer>
    );
};