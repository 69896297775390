import { Inventory2 } from "@mui/icons-material";
import { DataGridPremium, GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-premium";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GridToolbar, LayoutContext, TableContainer } from "wcz-layout";
import CycleCount from "../../models/CycleCount";
import { CycleCountStatus } from "../../models/enums/CycleCountStatus";
import { useGetCycleCounts } from "../../queries/CycleCountQueries";

export const CycleCountsPage: React.FC = () => {
    const { t } = useContext(LayoutContext);
    const navigate = useNavigate();

    const { data, isFetching } = useGetCycleCounts();

    const handleOnRowDoubleClick = (params: GridRowParams) => navigate(`/cycle-counts/${params.id}`);

    const columns: GridColDef<CycleCount>[] = [
        {
            field: "actions", type: "actions", width: 50, getActions: ({ id }) => [
                <GridActionsCellItem key="material" label={t("Material")} icon={<Inventory2 />} onClick={() => navigate(`/cycle-counts/${id}`)} />,
            ],
        },
        { field: "name", headerName: t("Name"), width: 250, },
        { field: "status", headerName: t("Status"), width: 150, type: "singleSelect", valueOptions: Object.values(CycleCountStatus) },
        { field: "startDate", headerName: t("StartDate"), width: 150, type: "date", valueGetter: value => value && new Date(value) },
        { field: "endDate", headerName: t("EndDate"), width: 150, type: "date", valueGetter: value => value && new Date(value) },
    ];

    return (
        <TableContainer>
            <DataGridPremium
                rows={data}
                columns={columns}
                slots={{ toolbar: GridToolbar }}
                onRowDoubleClick={handleOnRowDoubleClick}
                loading={isFetching}
                ignoreDiacritics
            />
        </TableContainer>
    );
};